
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Button, Watermark, Select, Space, Tabs, Card } from 'antd';
import Settings from '../../dependencies/custom/settings';
import ReactToPrint from 'react-to-print';
import CustomFunction from '../../dependencies/custom/custom-functions';
import useAdd from '../../hooks/add';

import { View, Document, Page, Image, Text, PDFViewer, Font } from '@react-pdf/renderer';
import AppContainer from '../../components/container';

const Transcript = (props) => {
    const metaName = 'transcriptFilters';
    const valuesStore = ValuesStore();
    const [transcript, setTranscript] = useState([]);
    const [officialTranscript, setOfficialTranscript] = useState([]);
    const [records, setRecords] = useState([]);
    const [calculatedValues, setCalculatedValues] = useState([]);
    const [indexNumber, setIndexNumber] = useState();
    const add = useAdd(metaName, 'table_name');
    const [schoolDetails, setSchoolDetails] = useState();
    const [transcriptPermissions, setTranscriptPermissions] = useState({});
    const printable = useRef();

   
    function generateTranscript() {
        const values = calculatedValues;
        values.forEach(v => {
            setTranscript((t, i) => {
                return [...t, transcriptTemplate(v)];
            });
            setOfficialTranscript((t, i) => {
                return [...t, pdfTranscriptTemplate(v)];
            });
        });
    }

    useMemo(() => {
        console.log('looping');
        showWithPerm();
        generateTranscript();
        genFilters();
        const institution = valuesStore.getArrayObjectsValue('settings', 'prop', 'INSTITUTION_DETAILS')?.value;
        if (institution) {
            setSchoolDetails(JSON.parse(institution));
        }

        // Font.register({
        //     family: 'Open Sans',
        //     fonts: [
        //         { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        //         { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
        //     ]
        // });


    }, [records, transcriptPermissions]);


    function genFilters() {
        setTimeout(() => {
            valuesStore.setValue(metaName, [
                {
                    id: '', table_name: 'filters',
                    column_name: 'students.start_acadyr', col_real_name: 'Start Academic Year', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT acadyr FROM acad_year WHERE archived=0","key":"acadyr","value":"acadyr"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'students.end_acadyr', col_real_name: 'End Academic Year', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT acadyr FROM acad_year WHERE archived=0","key":"acadyr","value":"acadyr"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'students.start_level', col_real_name: 'Start Level', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT level FROM level","key":"level","value":"level"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'students.end_level', col_real_name: 'End Level', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT level FROM level","key":"level","value":"level"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'students.session', col_real_name: 'Session', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT session,alias from sessions","key":"alias","value":"session"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'course_id', col_real_name: 'Course Code', type: 'text',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'students.status', col_real_name: 'Status', type: 'csvMultiSelect',
                    options: 'active,deferred,suspended,completed',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'students.batch', col_real_name: 'Batch', type: 'sqlMultiSelect',
                    // options: '{"sql":"SELECT DISTINCT batch FROM students","key":"batch","value":"batch"}',
                    options: '{"sql":"SELECT batch FROM students GROUP BY batch","key":"batch","value":"batch"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'semester_id', col_real_name: 'Semester', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT semester,alias from semester","key":"alias","value":"semester"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'students.program', col_real_name: 'Program', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT name,alias from programs","key":"alias","value":"name"}',
                    backend_visible: 1,
                    rank: 1
                }
            ]);
            add.setTblName('filters');
        }, 1000);
    }

    function get() {
        setCalculatedValues([]);
        setRecords([]);
        setTranscript([]);
        CustomFunction.calc(setRecords, setCalculatedValues, { student_id: indexNumber?.join(), ...add.record });
    }

    function transcriptTemplate(student) {
        const semesters = student.semesters;
        let details = [];
        const lastCPGA = semesters[Object.keys(semesters).pop()]?.CGPA;//get the last semesters cgpa
        const semestersInStore = valuesStore.getValue('semester');
        for (let semester in semesters) {
            const semesterDetails = semesters[semester];
            const acadYr = semesterDetails.acadYr;
            const CCR = parseFloat(semesterDetails.CCR).toFixed(2);
            const CGPA = parseFloat(semesterDetails.CGPA).toFixed(2);
            const CGV = parseFloat(semesterDetails.CGV).toFixed(2);
            const SCR = parseFloat(semesterDetails.SCR).toFixed(2);
            const SGP = parseFloat(semesterDetails.SGP).toFixed(2);
            const SGPA = parseFloat(semesterDetails.SGPA).toFixed(2);
            const hasIC = semesterDetails.hasIC;
            let courses = semesters[semester]?.courses;

            const semesterText = semestersInStore.filter(v => semester == v.alias)[0]?.name;

            //find duplicate course codes
            const duplicateCourses = utils.groupBy(courses, 'course_id');

            let courseDetails = [];
            for (let key in duplicateCourses) {
                const entry = duplicateCourses[key];
                //if course appears more than once
                if (entry.length > 1) {
                    let totalCredit = 0;
                    let highestEntry = { ...entry[0] };//make the first item as the highest entry
                    let totalGradePoint = 0;
                    entry.forEach(e => {
                        totalCredit += parseFloat(e.credit);
                        totalGradePoint += parseFloat(e.grade_point);
                        if (e.grade_point > highestEntry.grade_point) {
                            highestEntry = e;
                        }
                    });

                    courseDetails.push([
                        <label style={{ fontSize: '8pt' }} key={utils.generateUuid()} className='fw-bold'>{highestEntry.course_id}</label>,
                        <label style={{ fontSize: '8pt' }} key={utils.generateUuid()} className='fw-bold'>{highestEntry.course_name}</label>,
                        <center style={{ fontSize: '8pt' }} key={utils.generateUuid()} className='fw-bold'>{totalCredit}</center>,
                        <center style={{ fontSize: '8pt' }} key={utils.generateUuid()} className='fw-bold'>{highestEntry.grade}</center>,
                        <center style={{ fontSize: '8pt' }} key={utils.generateUuid()} className='fw-bold'>{totalGradePoint}</center>
                    ]);
                } else {//then it has only one array item
                    const v = entry[0];
                    courseDetails.push([
                        <label style={{ fontSize: '8pt' }} key={utils.generateUuid()} className='fw-bold'>{v.course_id}</label>,
                        <label style={{ fontSize: '8pt' }} key={utils.generateUuid()} className='fw-bold'>{v.course_name}</label>,
                        <center style={{ fontSize: '8pt' }} key={utils.generateUuid()} className='fw-bold'>{v.credit}</center>,
                        <center style={{ fontSize: '8pt' }} key={utils.generateUuid()} className='fw-bold'>{v.grade}</center>,
                        <center style={{ fontSize: '8pt' }} key={utils.generateUuid()} className='fw-bold'>{v.grade_point}</center>
                    ]);
                }
            }


            courseDetails.push(
                [<div key={utils.generateUuid()} style={{ fontSize: '7pt' }} className='fw-bold px-3x border pinkx text-whitex darken-1 d-flex justify-content-between'>
                    <label>SGP:{SGP}</label>
                    <label>SGPA:{SGPA}</label>
                    <label>CCR:{CCR}</label>
                    <label>CGV:{CGV}</label>
                    <label>CGPA:{CGPA}</label>
                </div>]);
            const det = utils.getTable(
                [
                    <label>{acadYr}</label>,
                    <label>Academic Year {semesterText}</label>,
                    <center>Credits</center>,
                    <center>Grade</center>,
                    <center>Grade Point</center>
                ],
                courseDetails, 'w-100 ', 'pink darken-1 text-white', '', '', 5);
            details.push(det);
        }

        const classDesignations = valuesStore.getValuesBy('class_designation', 'edu_level', student?.eduLevel);
        const cd = classDesignations.filter(v => {
            return lastCPGA >= v.gpa_from && lastCPGA <= v.gpa_to;
        });


        return <Watermark
            className='printable'
            gap={[5, 100]}
            key={utils.generateUuid()}
            height={50}
            width={100}
            content={[schoolDetails.alias, 'UNOFFICIAL', 'TRANSCRIPT']}
        >

            <section className='d-flex border-bottom'>
                <div className='border-end'>
                    <img src={`${Settings.backend}/${schoolDetails.logo}`} width={schoolDetails?.logoWidth} height={schoolDetails?.logoHeight} />
                </div>
                <div className='w-100'>
                    <center className='d-flex flex-column'>
                        <label className='h6 ms-2'>{schoolDetails.name}</label>
                        <label className='h6' style={{ marginTop: '-0.5rem' }}>{schoolDetails.transcriptOffice}</label>
                        <label className='h6' style={{ marginTop: '-0.5rem' }}>{schoolDetails.pobox}</label>
                    </center>
                    <div className='d-flex justify-content-around px-5' style={{ marginTop: '-0.5rem' }}>
                        <label className='h6'>{schoolDetails.registrarEmail}</label>
                        <label className='h6'>{schoolDetails.website}</label>
                    </div>
                    <div className='pink darken-4 pt-1'><p style={{ textAlign: 'center' }} className='text-white h6'>UNOFFICIAL TRANSCRIPT OF ACADEMIC RECORD</p></div>
                </div>
                <div>

                </div>
            </section>


            <section>
                <div className="row row-cols-2">
                    <div className="col">
                        <p className='h6'>Student Number : {student.index_no}</p>
                        <p style={{ marginTop: '-0.5rem' }} className='h6'>Date of Birth: {student.dob}</p>
                        <p style={{ marginTop: '-0.5rem' }} className='h6'>Program : {student.program}</p>
                    </div>
                    <div className="col">
                        <p className='h6'>Name   : {student.student_name}</p>
                        <p style={{ marginTop: '-0.5rem' }} className='h6'>Period : {student.period}</p>
                        <p style={{ marginTop: '-0.5rem' }} className='h6'>Gender : {student.sex}</p>
                    </div>
                </div>
                {/* <div className='pink darken-4 pt-1'><p className='text-center text-white h6'>A BLACK AND WHITE DOCUMENT IS NOT OFFICIAL</p></div> */}
            </section>
            <section>
                {details}
                <label className='fw-bold'>Class Designation: {cd[0]?.designation}</label>
            </section>
        </Watermark>
    }//end 


    function handleChange(value) {
        setIndexNumber(value);
    }



    function header(student) {
        return <>
            {/* The Water mark */}
            <View style={{ position: 'absolute', width: '100%' }} fixed>
                <Image style={{ opacity: '.7' }} src={`${Settings.backend}/${schoolDetails?.transcriptWaterMarkImage}`} />
            </View>

            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }} fixed>
                {/* logo */}
                <Image style={{ width: '18%', border: 'solid black', borderBottom: '1px', borderRight: '1px' }} src={`${Settings.backend}/${schoolDetails.logo}`} />
                {/* heading */}
                <View style={{ width: '82%', fontSize: '10pt', marginTop: '10px' }}>
                    <Text style={{ textAlign: 'center', fontSize: '15pt', fontWeight: 'bold' }}>{schoolDetails.name}</Text>
                    <Text style={{ textAlign: 'center', fontWeight: 'bold' }}>{schoolDetails.transcriptOffice}</Text>
                    <Text style={{ textAlign: 'center' }}>{schoolDetails.pobox}</Text>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }} >
                        <Text style={{ textAlign: 'left' }}>{schoolDetails.registrarEmail}</Text>
                        <Text style={{ textAlign: 'right' }}>{schoolDetails.website}</Text>
                    </View>
                    <Text style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ec407a', color: 'white' }}>OFFICIAL TRANSCRIPT OF ACADEMIC RECORD</Text>
                </View>
                {/* bio-data */}
                <View style={{ display: 'flex', flexDirection: 'row', fontSize: '9px' }}>
                    <View style={{ width: '65%' }}>
                        <Text>Student Number : {student?.index_no}</Text>
                        <Text>Date of Birth: {student?.dob}</Text>
                        <Text>Programme : {student?.program}</Text>
                    </View>
                    <View style={{ width: '35%', marginLeft: '20px' }}>
                        <Text>Name   : {student?.student_name}</Text>
                        <Text>Period : {student?.period}</Text>
                        <Text>Gender : {student?.sex}</Text>
                    </View>
                </View>
                {/* deep pink strip */}
                <View style={{ width: '100%', fontSize: '10pt', textAlign: 'center', backgroundColor: '#ad1457', color: 'white', fontWeight: 'bold' }}>
                    <Text style={{ marginLeft: '100px' }}>A BLACK AND WHITE DOCUMENT IS NOT OFFICIAL</Text>
                </View>
            </View>
        </>
    }

    function rows(course_id, course_name, credit, grade, grade_point) {
        return <View key={utils.generateUuid()} style={{ display: 'flex', flexDirection: 'row', fontSize: '7px' }}>
            <Text style={{ width: "7%" }}>{course_id}</Text>
            <Text style={{ width: "75%" }}>{course_name}</Text>
            <Text style={{ width: "5%" }}>{credit}</Text>
            <Text style={{ width: "5%" }}>{grade}</Text>
            <Text style={{ width: "8%" }}>{grade_point}</Text>
        </View>
    }

    function firstRowHeader(acadYr, semesterText) {
        return <View key={utils.generateUuid()} style={{ display: 'flex', flexDirection: 'row', fontSize: '7px', backgroundColor: '#ec407a', color: 'white', marginTop: '1px' }}>
            <Text style={{ width: "7%" }}>{acadYr}</Text>
            <Text style={{ width: "75%" }}>Academic Year {semesterText}</Text>
            <Text style={{ width: "5%" }}>Credits</Text>
            <Text style={{ width: "5%" }}>Grade</Text>
            <Text style={{ width: "8%" }}>Grade Point</Text>
        </View>
    }

    function lastRow(SCR, SGP, SGPA, CCR, CGV, CGPA) {
        return <View key={utils.generateUuid()} style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'row', fontSize: '6px', justifyContent: 'space-between', border: 'solid black', borderBottom: '0.5px', borderTop: '0.5px' }}>
            <Text>TCR: {SCR}</Text>
            <Text>TGP: {SGP}</Text>
            <Text>SGPA:{SGPA}</Text>
            <Text>CCR: {CCR}</Text>
            <Text>CGV: {CGV}</Text>
            <Text>CGPA: {CGPA}</Text>
        </View>
    }

    function footer(student, lastCPGA) {
        const classDesignations = valuesStore.getValuesBy('class_designation', 'edu_level', student?.eduLevel);
        const cd = classDesignations.filter(v => {
            return lastCPGA >= v.gpa_from && lastCPGA <= v.gpa_to;
        });

        // const classDesignations = valuesStore.getValue('class_designation');
        // const cd = classDesignations.filter(v => {
        //     return lastCPGA >= v.gpa_from && lastCPGA <= v.gpa_to;
        // });
        return <View key={utils.generateUuid()} style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'row', fontSize: '9px', justifyContent: 'space-between' }}>
            <View>
                <Text>Class Designation: <Text style={{ fontWeight: 'bold' }}>{cd[0]?.designation}</Text></Text>
            </View>
            <View>
                <Image style={{ opacity: '.5', width: '150px', height: '40px', borderBottom: '1px solid black' }} src={`${Settings.backend}/${schoolDetails?.transcriptOfficiator?.signature}`} />
                {schoolDetails?.transcriptOfficiator?.texts?.map(v => (<Text key={v}>{v}</Text>))}
            </View>
        </View>
    }

    function pageNumber() {
        return <Text style={{
            position: 'absolute',
            fontSize: 12,
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey',
        }} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
        )} fixed />;
    }

    function pdfTranscriptTemplate(student) {
        const semesters = student.semesters;
        const lastCPGA = semesters[Object.keys(semesters).pop()]?.CGPA;//get the last semester's cgpa
        let details = [];
        for (let semester in semesters) {
            const semesterDetails = semesters[semester];
            const acadYr = semesterDetails.acadYr;
            const CCR = parseFloat(semesterDetails.CCR).toFixed(2);
            const CGPA = parseFloat(semesterDetails.CGPA).toFixed(2);
            const CGV = parseFloat(semesterDetails.CGV).toFixed(2);
            const SCR = parseFloat(semesterDetails.SCR).toFixed(2);
            const SGP = parseFloat(semesterDetails.SGP).toFixed(2);
            const SGPA = parseFloat(semesterDetails.SGPA).toFixed(2);
            const hasIC = semesterDetails.hasIC;
            let courses = semesters[semester]?.courses;
            const semestersInStore = valuesStore.getValue('semester');
            const semesterText = semestersInStore.filter(v => semester == v.alias)[0]?.name;

            //find duplicate course codes
            const duplicateCourses = utils.groupBy(courses, 'course_id');

            let courseDetails = [];
            for (let key in duplicateCourses) {
                const entry = duplicateCourses[key];
                //if course appears more than once
                if (entry.length > 1) {
                    let totalCredit = 0;
                    let highestEntry = { ...entry[0] };//make the first item as the highest entry
                    let totalGradePoint = 0;
                    entry.forEach(e => {
                        totalCredit += parseFloat(e.credit);
                        totalGradePoint += parseFloat(e.grade_point);
                        if (e.grade_point > highestEntry.grade_point) {
                            highestEntry = e;
                        }
                    });
                    courseDetails.push(rows(highestEntry.course_id, highestEntry.course_name, totalCredit, highestEntry.grade, totalGradePoint));
                } else {//then it has only one array item
                    const v = entry[0];
                    courseDetails.push(rows(v.course_id, v.course_name, v.credit, v.grade, v.grade_point));
                }

            }
            courseDetails.push(lastRow(SCR, SGP, SGPA, CCR, CGV, CGPA));
            const rh = firstRowHeader(acadYr, semesterText);
            details.push(rh, courseDetails);

        }

        return <Page key={utils.generateUuid()} size="A4" style={{ padding: '5px', border: '3px solid #880e4f' }}>
            {header(student)}
            {details}
            {footer(student, lastCPGA)}
            {/* show page number for only a single student  */}
            {indexNumber?.length == 1 && pageNumber()}
        </Page>
    }

    async function showWithPerm() {
        if (Object.keys(transcriptPermissions) <= 0) {
            const permList = [
                'generate_unoffical_transcript',
                'generate_offical_transcript'
            ];

            permList.forEach(async v => {
                const perm = await utils.hasPermission(v, null, valuesStore);
                setTranscriptPermissions(r => ({ ...r, [v]: perm }));//permission as key and true/false as value
            });
        }
    }


    const items = [
        {
            key: '1',
            label: `Unofficial Transcript`,
            children: transcriptPermissions?.generate_unoffical_transcript ? <div className='row p-4' ref={printable}>
                <div className='col-md-12'>
                    {transcript}
                </div>
            </div> : undefined,
        },
        {
            key: '2',
            label: `Official Transcript`,
            children: transcriptPermissions?.generate_offical_transcript ? officialTranscript.length ? <PDFViewer width={'100%'} height={'700px'}>
                <Document >
                    {officialTranscript}
                </Document>
            </PDFViewer> : undefined : null,
        }
    ];




    return (
        <>
            <AppContainer
                extra={
                    <div className='mb-2'>
                        <Button className='me-2 btn-primary border-0' onClick={e => get()}><i className='fas fa-paper-plane me-2' />QUERY</Button>
                        <ReactToPrint
                            trigger={() => <Button className='btn-success border-0'><i className='fas fa-print' />&emsp;Print out</Button>}
                            content={() => printable.current}
                        />
                    </div>
                }
                title={'Transcript'}
                children={
                    <>
                        <div className='mt-2'>
                            <span className="">Index Number(s)</span>
                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                tokenSeparators={[',']}
                                className='mb-2'
                                onChange={handleChange}
                                placeholder='Enter Index Number(s)'
                            />
                        </div>
                        <div className='row row-cols-2'>
                            {add?.form?.map((v, i) => v && <div key={i}>{v}</div>)}
                        </div>
                        <Tabs defaultActiveKey="1" items={items} />
                    </>
                }
            />
           
        </>
    );
}

export default memo(Transcript);