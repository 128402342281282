
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import utils from '../../dependencies/custom/react-utilities';
import { Button, Select, Input, Divider, message, Tabs } from 'antd';
import Settings from '../../dependencies/custom/settings';
// import ReactToPrint from 'react-to-print';
// import CustomFunction from '../../dependencies/custom/custom-functions';
import useAdd from '../../hooks/add';
import useExcel from '../../hooks/excel';
import useUpload from '../../hooks/upload';
import useTable from '../../hooks/table';
import cinzel from '../../../font/others/CinzelDecorative-Bold.otf';
// import roboto from '../../../font/roboto/Roboto-Regular.ttf';
import lucidaBlack from '../../../font/others/Lucida-Blackletter-Regular.ttf';
import primousItalic from '../../../font/others/Primous-Italic.ttf';
import antigoni from '../../../font/others/Antigoni.ttf';
import berkshireSwashRegular from '../../../font/others/BerkshireSwash-Regular.ttf';

import { View, Document, Page, Image as PFDImage, Text, PDFViewer, Font } from '@react-pdf/renderer';
import AppContainer from '../../components/container';
import { QRCodeCanvas } from 'qrcode.react';


const Cert = (props) => {
    const metaName = 'transcriptFilters';
    const valuesStore = ValuesStore();

    const [indexNumber, setIndexNumber] = useState();
    const [queryLabel, setQueryLabel] = useState();
    const add = useAdd(metaName, 'table_name');
    const excel = useExcel();
    const upload = useUpload();
    const [schoolDetails, setSchoolDetails] = useState();
    const [transcriptPermissions, setTranscriptPermissions] = useState({});
    const qrc = useRef({});
    const [config, setConfig] = useState([]);
    const [pages, setPages] = useState([]);
    const [startNumber, setStartNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [label, setLabel] = useState('');
    const [QRCs, setQRCs] = useState();
    const [queryLabels, setQueryLabels] = useState([]);
    const [selectedLabel, setSelectedLabel] = useState();

    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 100,
                position: ['topRight', 'bottomRight']
            },
            // filters: { ...filters },
            // filterTypes: { ...filterTypes }
        },
        undefined,
        undefined,
        undefined,
        undefined,
        'student_id',
        {},
        {});

    const columns = ([
        {
            title: 'Index',
            dataIndex: 'student_id',
            ...table.getColumnSearchProps('student_id'),
        },
        {
            title: 'Name',
            dataIndex: 'cert_name',
            ...table.getColumnSearchProps('cert_name'),

        },
        {
            title: 'Gender',
            dataIndex: 'sex',
            ...table.getColumnSearchProps('sex'),
        },
        {
            title: 'Session',
            dataIndex: 'session',
            ...table.getColumnSearchProps('session'),

        },
        {
            title: 'Program',
            dataIndex: 'cert_program',
            render(v, record) {
                return `${record.cert_program_category}(${v})`;
            }
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            ...table.getColumnSearchProps('designation'),

        },
        {
            title: 'CertNumber',
            dataIndex: 'cert_no',
            ...table.getColumnSearchProps('cert_no'),

        }
    ]);

    useMemo(() => {
        console.log('looping');
        // showWithPerm();
        getLabels();
        table.setColumns(columns);
        // qrCode();
        genFilters();
        setTimeout(() => {
            const institution = valuesStore.getArrayObjectsValue('settings', 'prop', 'INSTITUTION_DETAILS')?.value;
            if (institution) {
                setSchoolDetails(JSON.parse(institution));
            }
            const conf = valuesStore.getValue('education_level');
            if (conf) {
                setConfig(conf);
            }
        }, 1000);


        Font.register(
            {
                family: 'Lucida Blackletter',
                fonts: [
                    { src: lucidaBlack }
                ]
            }
        );
        Font.register(
            {
                family: 'Primous Italic',
                src: primousItalic
            }
        );
        Font.register(
            {
                family: 'Antigoni',
                src: antigoni
            }
        );
        Font.register(
            {
                family: 'Cinzel',
                src: cinzel
            }
        );
        Font.register(
            {
                family: 'Berkshire',
                src: berkshireSwashRegular
            }
        );

        Font.registerHyphenationCallback(word => [word]); // Disable hyphenation

    }, [/*qrc.current*/]);




    function genFilters() {
        setTimeout(() => {
            valuesStore.setValue(metaName, [
                {
                    id: '', table_name: 'filters',
                    column_name: 'cert_list.start_acadyr', col_real_name: 'Start Academic Year', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT start_acadyr FROM cert_list GROUP BY start_acadyr","key":"start_acadyr","value":"start_acadyr"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'cert_list.end_acadyr', col_real_name: 'End Academic Year', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT end_acadyr FROM cert_list GROUP BY end_acadyr","key":"end_acadyr","value":"end_acadyr"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'cert_list.start_level', col_real_name: 'Start Level', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT start_level FROM cert_list GROUP BY start_level","key":"start_level","value":"start_level"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'cert_list.end_level', col_real_name: 'End Level', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT end_level FROM cert_list GROUP BY end_level","key":"end_level","value":"end_level"}',
                    backend_visible: 1,
                    rank: 1
                },
                {
                    id: '', table_name: 'filters',
                    column_name: 'cert_list.session', col_real_name: 'Session', type: 'sqlMultiSelect',
                    options: '{"sql":"SELECT session from cert_list GROUP BY session","key":"session","value":"session"}',
                    backend_visible: 1,
                    rank: 1
                },
                // {
                //     id: '', table_name: 'filters',
                //     column_name: 'students.batch', col_real_name: 'Batch', type: 'sqlMultiSelect',
                //     options: '{"sql":"SELECT DISTINCT batch FROM students","key":"batch","value":"batch"}',
                //     backend_visible: 1,
                //     rank: 1
                // },               
                {
                    id: '', table_name: 'filters',
                    column_name: 'cert_list.program', col_real_name: 'Program', type: 'sqlMultiSelect',
                    // options: '{"sql":"SELECT name,alias from programs","key":"alias","value":"name"}',
                    options: '{"sql":"SELECT program from cert_list GROUP BY program","key":"program","value":"program"}',
                    backend_visible: 1,
                    rank: 1
                }
            ]);
            add.setTblName('filters');
        }, 1000);
    }

    async function get() {
        if (label == '' || startNumber == '' /*|| endNumber == ''*/) {
            message.error('Please set label, start number and end number');
            return;
        }

        let cf = { ...add.record };
        if (indexNumber && indexNumber?.length) {
            cf['student_id'] = indexNumber?.join();
        }

        const vals = Object.values(cf)?.filter(v => v);
        if (!vals.length) {
            message.error('Please at least one filter');
            return;
        }
        setLoading(true);
        const res = await utils.requestWithReauth('post', `${Settings.backend}/get_cert_list`, null, { queryLabel, certFilters: cf, certLabel: { label, startNumber/*, endNumber*/ } });
        setQRCs(generateQRCs(res?.result));
        await utils.sleep(1000);
        const result = page(res?.result);
        setPages(result);
        setLoading(false);
    }

    async function getLabels() {
        const res = await utils.requestWithReauth('post', `${Settings.backend}/get_cert_labels`, null, {});
        if (res?.status == 'Ok') {
            setQueryLabels(res?.result);
        }
    }

    async function getLabelList() {
        if (!selectedLabel) {
            message.error('Please select a label first');
            return;
        }
        const selectedQuery = queryLabels?.filter(v => v.label == selectedLabel);
        if (selectedQuery.length) {
            const res = await utils.requestWithReauth('post', `${Settings.backend}/get_cert_labels_query_result`, null, { query: selectedQuery[0]?.query });
            if (res?.status == 'Ok') {
                console.log(res)
                table.setData(res?.result);
            }
        }
    }


    function handleChange(value) {
        setIndexNumber(value);
    }

    // async function showWithPerm() {
    //     if (Object.keys(transcriptPermissions) <= 0) {
    //         const permList = [
    //             'generate_unoffical_transcript',
    //             'generate_offical_transcript'
    //         ];

    //         permList.forEach(async v => {
    //             const perm = await utils.hasPermission(v, null, valuesStore);
    //             setTranscriptPermissions(r => ({ ...r, [v]: perm }));//permission as key and true/false as value
    //         });
    //     }
    // }

    function getConf(eduLevel) {
        const a = config?.filter(v => {
            return v?.id == eduLevel;
        });
        if (a.length) {
            return a[0];
        }
        return {};
    }

    function processName(name) {
        return name?.split(' ')?.filter(v => v)?.map(v => {
            return v?.trim();
        })?.join(' ');

    }


    function setRef(element, key) {
        qrc.current[key] = element;
    }


    function qrImg(qrCanvas) {
        return qrCanvas?.toDataURL('image/jpg', .3);
    }

    function generateQRCs(students) {
        return students?.map((student) => {
            return (<QRCodeCanvas key={student?.student_id} ref={el => setRef(el, student?.student_id)} value={`https://cert.aamusted.edu.gh/verify_cert/${student?.student_id}`} style={{ display: 'none' }} />);
        });
    }



    function page(students) {
        return students?.map((v) => {
            const conf = getConf(v?.edu_level);
            const orientation = conf?.page_orientation;
            const stmt1 = conf?.stmt1;
            const stmt2 = conf?.stmt2;
            const showDegree = parseInt(conf?.show_degree);
            const index = v?.student_id;
            const css = JSON.parse(conf?.css || "{}");
            // console.log(qrc?.current[index]);
            const qrImage = qrImg(qrc?.current[index]);
            return <Page key={utils.generateUuid()} style={css?.page} orientation={orientation}>
                <View style={{ textAlign: 'center' }}>
                    <View style={css?.header || {}}>
                        <Text>This is to Certify that</Text>
                    </View>
                    <View style={css?.name || {}}>
                        <Text>{processName(v?.cert_name)}</Text>
                    </View>
                    <View style={css?.stmt || {}}>
                        <Text>
                            {stmt1}
                        </Text>
                        <Text>
                            {stmt2}
                        </Text>
                    </View>
                    {showDegree && <View style={css?.degree || {}}>
                        <Text>
                            {v?.cert_program_category != 'N/A' && v?.cert_program_category}
                        </Text>
                    </View>}
                    <View style={css?.program || {}}>
                        <Text>
                            {showDegree ? `(${v?.cert_program})` : v?.cert_program}
                        </Text>
                    </View>
                    <View style={css?.class || {}}>
                        <Text>
                            {v?.designation}
                        </Text>
                    </View>
                    <View style={css?.assertion || {}}>
                        <Text>
                            Given under our hand
                        </Text>
                    </View>
                    <View style={css?.qrcwrapper}>
                        <Text style={css?.qrctext}>{index}</Text>
                        <PFDImage style={css?.qrcimg} source={{ uri: qrImage }} />
                    </View>
                    <View style={css?.seal}>
                        <Text>
                            Seal
                        </Text>
                    </View>
                    <View style={css?.signature}>
                        <View>
                            <PFDImage style={{ opacity: '.5', width: '110px', height: '40px', borderBottom: '1px solid black' }} src={`${Settings.backend}/${schoolDetails?.certOfficiator?.vc}`} />
                            <Text style={{ marginLeft: '2px' }}>
                                Vice-Chancellor
                            </Text>
                        </View>
                        <View>
                            <PFDImage style={{ opacity: '.5', width: '90px', height: '40px', borderBottom: '1px solid black' }} src={`${Settings.backend}/${schoolDetails?.certOfficiator?.registrar}`} />
                            <Text style={{ marginLeft: '16px' }}>
                                Registrar
                            </Text>
                        </View>
                    </View>
                </View>
            </Page>
        });
    }

    function printCertTab() {
        return <>
            <Divider orientation='left'>Label</Divider>
            <div className='row row-cols-2'>
                <div className='mb-3'>
                    <Input placeholder='Enter label' value={label} onChange={e => setLabel(e.target.value)} />
                </div>
                <div>
                    <Input placeholder='Enter Start Number' type='Number' value={startNumber} onChange={e => setStartNumber(e.target.value)} />
                </div>
                {/* <div>
                                <Input placeholder='Enter End Number' type='Number' value={endNumber} onChange={e => setEndNumber(e.target.value)} />
                            </div> */}
            </div>

            <Divider orientation='left'>Filter</Divider>
            <div className=''>
                <span className="">Index Number(s)</span>
                <Select
                    mode="tags"
                    style={{ width: '100%' }}
                    tokenSeparators={[',']}
                    className='mb-2'
                    onChange={handleChange}
                    placeholder='Enter Index Number(s)'
                />
            </div>
            <div className='row row-cols-2 mb-3'>
                {add?.form?.map((v, i) => v && <div key={i}>{v}</div>)}
            </div>


            {QRCs}

            <PDFViewer width={'100%'} height={'1000px'}>
                <Document>
                    {pages}
                </Document>
            </PDFViewer>
        </>
    }

  
    function downloadList() {
        let data = [];
        let di = [];
        const cols = columns.map(v => {
            di.push(v.dataIndex);
            return v.title;
        });
        table.data?.forEach(v => {
            let obj = {};
            di.forEach(vv => {
                obj[vv] = v[vv];
            });
            data.push(obj);
        });
        excel.exportXLSX(cols, data, 'g_list', `g_list_${new Date().getDate()}.xlsx`);
    }

    function tableHeader() {
        return <>
            <label className='fw-bold text-secondary'>Total Count: {table.data.length}</label>
            <Button icon={<i className='fas fa-download' />} className='ms-3' onClick={e => downloadList()}>Download</Button>
        </>
    }

    function certList() {
        return <>
            <Select
                placeholder='Select label'
                options={[...queryLabels?.map((v) => { return { value: v?.label, label: v?.label } })]}
                onChange={value => setSelectedLabel(value)}
            />
            <Button className='ms-3' icon={<i className='fas fa-paper-plane'/>} onClick={e => getLabelList()}>Fetch List</Button>

            {table.tableWithHeader(tableHeader)}

        </>
    }

    const tabItems = [
        {
            key: '1',
            label: 'Print',
            children: printCertTab()
        },
        {
            key: '2',
            label: 'List',
            children: certList()
        },
        {
            key: '3',
            label: 'Upload',
            children: <>
                {upload.uploader('fileUpload', '', 'uploadMap', ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'])}
            </>
        }
    ];

    return (
        <>
            <AppContainer
                extra={
                    <div className='mb-2'>
                        <Button className='me-2 btn-primary border-0' loading={loading} icon={<i className='fas fa-paper-plane' />} onClick={e => get()}>QUERY</Button>
                    </div>
                }
                title={'Certification'}
                children={
                    <>
                        <Tabs defaultActiveKey="1" items={tabItems} />
                    </>
                }
            />

        </>
    );
}

export default memo(Cert);