
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Button, DatePicker, Layout, Divider } from 'antd';
import Settings from '../../dependencies/custom/settings';
import Input from 'antd/es/input/Input';
import { UserOutlined, LockOutlined } from '@ant-design/icons'


const CheckGraduationStat = (props) => {
    // const valuesStore = ValuesStore();
    const [indexNo, setIndexNumber] = useState();
    const [details, setDetails] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [btnLoading1, setBtnLoading1] = useState(false);
    const [recordFound, setRecordFound] = useState(false);
    // const [schoolDetails, setSchoolDetails] = useState();
    // const [url, setURL] = useState();
    async function check() {
        setBtnLoading(true);
        const res = await utils.request('post', `${Settings.backend}/check_graduation`, null, { indexNo: indexNo?.trim() });
        if (res?.status == 'Ok' && res?.details?.length) {
            let p = res.details[0];
            setDetails(p);
            setRecordFound(true);
        } else {
            utils.showNotification(undefined, 'Could not find data');
            setDetails([]);
            setRecordFound(false);
        }
        setBtnLoading(false);
    }

    async function register() {
        setBtnLoading1(true);
        const ind = indexNo?.trim();
        if (ind == '') {
            utils.showNotification(undefined, 'Please check your status first');
            return;
        }
        const res = await utils.request('post', `${Settings.backend}/register_graduation`, null, { indexNo: ind });
        if (res?.status == 'Ok') {
            utils.showNotification(undefined, 'Operation successful', 'text-success');
        } else {
            utils.showNotification(undefined, 'Operation failed');
        }
        setBtnLoading1(false);
    }


    useMemo(() => {
        // const institution = valuesStore.getArrayObjectsValue('settings', 'prop', 'INSTITUTION_DETAILS')?.value;
        // if (institution) {
        //     setSchoolDetails(JSON.parse(institution));
        // }
        // const link = valuesStore.getArrayObjectsValue('settings', 'prop', 'indexNoCheckURL')?.value;
        // console.log(valuesStore.getValue('settings'))
        // if (link) {
        //     setURL(link);
        // }

    }, []);


    return (
        <>
            <div className='container'>

                <div className='row'>
                    <Divider className='teal-text'><label className='h1'>Graduation Status</label></Divider>
                    <center>
                        <label className='text-danger h4'>Graduate students are kindly requested to exercise patience while we update the graduation list with their information.</label>
                    </center>
                    <div className='col-sm-12 col-md-6 col-lg-6 mx-auto mb-2 borderx special-colorx rounded' style={{ marginTop: '2%' }}>
                        <Space className='w-100' direction='vertical'>
                            <div className='row'>
                                <div className='p-2 col-6x'>
                                    <div className='d-flex justify-content-center special-colorx rounded' >
                                        <img className='mx-auto mb-3' width={190} src={`${Settings.backend}/fav.png`} />
                                        {/* <img className='mx-auto mb-3' width={schoolDetails?.logoWidth} src={`${Settings.backend}/${schoolDetails?.logo}`} /> */}
                                    </div>
                                    <Input onPressEnter={e => check()} className='mb-2x rounded' type='text' prefix={<UserOutlined className="site-form-item-icon" />} placeholder='Enter your Index Number' value={indexNo} onChange={e => setIndexNumber(e.target.value)} />
                                    <Button loading={btnLoading} className='btn-success border-0 mt-2 me-2' onClick={e => check()} icon={<i className='fas fa-check' />}>Check</Button>
                                    <Button loading={btnLoading1} className='btn-success border-0 mt-2' onClick={e => register()} icon={<i className='fas fa-paper-plane' />}>Register</Button>
                                </div>

                                {recordFound ? <div className='col-6x border-startx p-2' style={{ fontSize: '14pt' }}>
                                    <label className='fw-bold'>Index Number: {details.student_id}</label><br />
                                    <label style={{ color: '#3F729B' }} className='fw-bold'>Graduand Name: {details.cert_name}</label><br />
                                    <label style={{ color: '#3F729B' }} className='fw-bold'>Gender: {details.sex}</label><br />
                                    <label style={{ color: '#3F729B' }} className='fw-bold'>Program: {details.cert_program_category}{`(${details.cert_program})`}</label><br />
                                    <label style={{ color: '#3F729B' }} className='fw-bold'>Session: {details.session}</label><br />
                                    <label style={{ color: '#3F729B' }} className='fw-bold text-danger'>Designation: {details.designation}</label>
                                </div> : <></>}

                            </div>

                        </Space>
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(CheckGraduationStat);


